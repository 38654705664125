<template>
  <div class="app-container v">
    <div class="head-container">
      <quick-select v-model="query.sellerId" filterable url="api/purchase/seller" placeholder="选择供应商" value-field="belongTo" display-field="belongToName" auto-select-first-option @change="reQuery" class="filter-item" style="width: 180px;" />
      <quick-select v-model="query.erpCode" filterable url="api/distributorErps/list" placeholder="下单账户" value-field="erpId" display-field="name" @change="reQuery" class="filter-item" style="width: 180px;" auto-select-first-option/>
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="记录日期起始" end-placeholder="记录日期截止" :picker-options="searchDateRangeOptions" value-format="timestamp" :clearable="false" :default-time="['00:00:00', '23:59:59']" @change="reQuery" style="width: 240px;" />

      <el-button class="filter-item" type="success" icon="el-icon-search" @click="reQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <el-table v-loading="loading" row-key="id" :data="data" highlight-current-row empty-text="未找到对账信息" height="200px">
      <!-- 审核标志	日期	发票号码	购货单位代码	购货单位	产品长代码	产品名称	规格型号	单位	数量	单价	折扣率(%)	折扣额	价税合计	税额	凭证字号	摘要	不含税金额	订单单号	源单单号	备注	产品分类	发票号	客户单号	客户料号 -->
      <!-- "Amount": 31140.000000000,
                    "Comment": null,
                    "ItemCode": "302010015.0004",
                    "ItemName": "初心之选床垫~180*200*24",
                    "LineNo": 10,
                    "NonTaxPrice": 2755.752000000,
                    "Quantity": 10.000000000,
                    "SrcDocLineNo": null,
                    "SrcDocNo": null,
                    "TaxPrice": 3114.000000000 -->
      <el-table-column prop="DocDate" label="单据日期" width="83" :formatter="r => { return r.DocDate?new Date(r.DocDate).format('yyyy-MM-dd'):'' }" />
      <el-table-column prop="DocNo" label="发票号码" width="135"/>
      <el-table-column prop="CustomerCode" width="100" label="购货单位编码" />
      <!-- <el-table-column prop="CustomerName" width="140" label="购货单位" show-overflow-tooltip/> -->
      <el-table-column prop="ItemCode" width="120" label="产品代码" />
      <el-table-column prop="ItemName" min-width="180" label="产品名称" show-overflow-tooltip/> 
      <el-table-column prop="Quantity" label="数量" width="60"/>
      <el-table-column prop="TaxPrice" label="含税单价" width="75"/>  
      <el-table-column prop="Amount" label="价税合计" min-width="100"/>
      <!-- <el-table-column label="折扣率(%)" :formatter="r=>{return r.AdjustedPrice? ((r.TaxPrice/Number(r.AdjustedPrice))).toFixed(2)*100:'-'}"/> -->
      <el-table-column label="折扣额" :formatter="r=>{return r.AdjustedPrice?(r.Quantity*(Number(r.AdjustedPrice)-r.TaxPrice)).toFixed(2):0}">
      </el-table-column>
      <el-table-column prop="NonTaxPrice" label="不含税单价" width="80"/>      
      <el-table-column prop="Amount" label="不含税金额" :formatter="r=>{return ((r.Quantity*r.NonTaxPrice)).toFixed(2)}"/>
      <el-table-column label="税额" :formatter="r=>{return (r.Amount-(r.Quantity*r.NonTaxPrice)).toFixed(2)}" min-width="100"/>
      <el-table-column prop="SrcDocNo" width="130" label="源单单号" show-overflow-tooltip/>
      <el-table-column prop="Description" label="备注" show-overflow-tooltip />
      <!-- <el-table-column prop="Item.ItemNum" width="120" label="产品代码" />
      <el-table-column prop="Item.ItemName" min-width="150" label="产品名称" /> -->
      <!-- <el-table-column prop="Item.ItemModel" min-width="120" label="规格型号" />
      <el-table-column prop="Item.Measurement.Name" label="单位" /> -->
      <!-- <el-table-column prop="Quantity" label="数量" />
      <el-table-column prop="UnitPrice" label="单价" />
      <el-table-column prop="DiscountRate" label="折扣率(%)" />
      <el-table-column prop="DiscountAmount" label="折扣额" />
      <el-table-column prop="LineTotal" label="价税合计" />
      <el-table-column prop="TaxAmount" label="税额" />
      <el-table-column prop="Digest" label="摘要" />
      <el-table-column label="不含税金额" width="100" :formatter="r=>{return (r.LineTotal-r.TaxAmount).toFixed(2)}" />
      <el-table-column prop="SalesOrderNum" label="订单单号" />
      <el-table-column prop="BaseNum" label="源单单号" />
      <el-table-column prop="Description" label="备注" show-overflow-tooltip /> -->
      
    </el-table>
    <!-- <el-pagination v-if="statementType!==sertaStatementType" :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" /> -->
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      queryable: false,
      query: {
        buyerId: null,
        dateRange: [],
      },
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/invoices/buyer";
      let query = JSON.parse(JSON.stringify(this.query));
      let required = false;
      if (query.sellerId && query.erpCode) {
        if (query.dateRange && query.dateRange.length === 2) {
          required = true;
          query.begTime = query.dateRange[0];
          query.endTime = query.dateRange[1];
        }
        delete query.dateRange;
      }
      if (required) {
        this.params = query;
        this.params.sort = this.sort;
      } 
      this.queryable = required;
      return required;
    },
    handleSort(sort) {
      if (sort.order == null) this.sort = "";
      else
        this.sort =
          sort.prop + "," + (sort.order === "ascending" ? "asc" : "desc");
      this.toQuery();
    },

    reQuery() {
      this.current = null;
      this.toQuery();
      // this.calcCount();
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/invoices/buyer/download", this.params)
        .then((result) => {
          downloadFile(result, "对账明细", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
  mounted() {
    let now = new Date();
    this.query.dateRange = [
      new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate(),
        0,
        0,
        0
      ).getTime(),
      new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        23,
        59,
        59
      ).getTime(),
    ];
  },
};
</script>